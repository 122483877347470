import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import LogoIcon from "../../svg/yogost_logo.png";

const Header = () => (
  <header className="sticky top-0 bg-white shadow z-20 rounded-bl-lg rounded-br-lg border-slate-50">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <AnchorLink href="#home" className="flex">
          <img
            className="w-30 h-6"
            src={LogoIcon}
            alt="Logo"
          />
        </AnchorLink>
      </div>
      <div className="flex mt-2 sm:mt-0">
        <AnchorLink className="px-2" href="#menu">
          Menu
        </AnchorLink>
        <AnchorLink className="px-2" href="#stores">
          Stores
        </AnchorLink>
        {/* <AnchorLink className="px-4" href="#aboutus">
          About
        </AnchorLink> */}
        <AnchorLink className="px-2" href="#contactus">
          Contact Us
        </AnchorLink>
        <AnchorLink className="px-2" href="#mobileapp">
          Mobile App
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        {/* <Button className="text-sm">Start Free Trial</Button> */}
      </div>
    </div>
  </header>
);

export default Header;
