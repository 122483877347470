import React, { Component } from 'react';
import ReactModal from 'react-modal';
import fbIcon from "../svg/fb_icon.png";
import instaIcon from "../svg/insta_icon.png";
import weChatIcon from "../svg/we_chat_icon.png";
import weChatBarcode from "../svg/wechat_barcode.png";
import lineIcon from "../svg/line_icon.png";
import lineBarcode from "../svg/line_barcode.jpeg";


class WeChatModal extends Component {

  constructor() {
    super();
    this.state = {
      showModal: false,
      showModalLine: false
    };

    this.handleOpenModal = this.handleOpenModalWeChat.bind(this);
    this.handleCloseModal = this.handleCloseModalWeChat.bind(this);
    this.handleOpenModalLine = this.handleOpenModalLine.bind(this);
    this.handleCloseModalLine = this.handleCloseModalLine.bind(this);
  }

  handleOpenModalWeChat() {
    this.setState({ showModal: true });
  }

  handleCloseModalWeChat() {
    this.setState({ showModal: false });
  }

  handleOpenModalLine() {
    this.setState({ showModalLine: true });
  }

  handleCloseModalLine() {
    this.setState({ showModalLine: false });
  }

  CustomStyles = {
    overlay: {
      backgroundColor: 'rgba(32, 40, 46, 0.5)',
      display: 'flex',
      'align-items': 'center',
    },
  };

  render() {
    return (

                <div className="justify-center block flex pb-2 ">

        <div className="justify-center block flex pb-2 ">
          <a href="https://www.facebook.com/YogostPerth" target="_blank" rel="noreferrer"><img
            className="w-30 h-6"
            src={fbIcon}
            alt="Yogost-Australia-Facebook-Icon"
          /></a>
          <a href="https://www.instagram.com/yogost_perth" target="_blank" rel="noreferrer"><img
            className="w-30 h-6 pl-1"
            src={instaIcon}
            alt="Yogost-Australia-Instagram-Icon"
          /></a>
          <button onClick={this.handleOpenModal}><img
            className="w-30 h-6 pl-1"
            src={weChatIcon}
            alt="Yogost-Australia-WeChat-Icon"
          /></button>
          <button onClick={this.handleOpenModalLine}><img
            className="w-30 h-6 pl-1"
            src={lineIcon}
            alt="Yogost-Australia-Line-Icon"
          /></button>
        </div>

        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="WeChat-QR-Code-Yogost-Australia-Modal"
          onRequestClose={this.handleCloseModal}
        >
          <button className="pt-8" onClick={this.handleCloseModal}>X</button>

          <div className="justify-center flex pt-56 ">
            <img
              src={weChatBarcode}
              alt="Yogost-Australia-WeChat-Barcode"
            />
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.showModalLine}
          contentLabel="WeChat-QR-Code-Yogost-Australia-Modal"
          onRequestClose={this.handleCloseModalLine}
        >
          <button className="pt-8" onClick={this.handleCloseModalLine}>X</button>

          <div className="justify-center flex pt-56">
            <img
              src={lineBarcode}
              alt="Yogost-Australia-Line-Barcode"
            />
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default WeChatModal