import React from "react";
import WeChatModal from "../WeChatModal";
import googlePlay from "../../svg/googlePlay.png";
import appStore from "../../svg/appStore.png";
import appIcon from "../../svg/appIcon.png";

const Footer = () => (
  <footer className="text-center">
    <div className="justify-center block flex pb-2" id="mobileapp">
      <img className="w-42 h-10" src={appIcon} alt="Yogost-Australia-Appicon" />
    </div>
    <p className="text-lg pb-1 font-semibold">Download the App</p>
    <p className="text-lg pb-1 font-lightbold">Earn loyalty points & skip the queue</p>

    <div className="justify-center block flex pb-2">
      <a
        href="https://apps.apple.com/au/app/yogost-au/id1531464754"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="w-42 h-10 pr-2"
          src={appStore}
          alt="Yogost-Australia-Appstore"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=au.com.yogost.preorder"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="w-42 h-10 pr-2"
          src={googlePlay}
          alt="Yogost-Australia-Google-Play"
        />
      </a>
    </div>

    <p className="text-lg pb-1 pt-5 font-semibold">Follow us on social media</p>
    <WeChatModal />
    <small className="pb-10">
      &copy; Copyright {new Date().getFullYear()}, HEY! I AM YOGOST. All rights
      reserved
    </small>
  </footer>
);

export default Footer;
